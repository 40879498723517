<template>
  <div class="container my-md-4 py-4 py-md-0 border-md-bottom">
    <div class="row row-header">
      <div class="navbar-header col-md-3 d-flex align-items-center col-sm-12">
        <Logo @toggleMenuMobile="showMobileMenu = !showMobileMenu" />
      </div>
      <div class="first-part col-md-9 col-sm-12 align-items-center">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-8 search-form-product align-items-center">
            <form action="shop" method="GET">
              <Search
                :placeholder="'Search car model...'"
                :categoriesOptions="categories"
                :buttonType="'submit'"
                :selectDefault="'Categories'"
              />
            </form>
          </div>
          <div class="col-md-3 menu-products">
            <!-- <Cart /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <navbar></navbar>
  <transition name="grow">
    <mobile-menu-bars v-if="showMobileMenu"></mobile-menu-bars>
  </transition>
</template>
<script>
import Logo from "../atoms/Logo/Logo.vue";
import Cart from "../molecules/Cart/Cart.vue";
import Search from "../molecules/Search/Search.vue";
import Navbar from "../molecules/Navigation/Navigationbar.vue";
import MobileMenuBars from "../molecules/Mobile/MobileMenuBars.vue";

export default {
  components: { Logo, Cart, Search, Navbar, MobileMenuBars },
  data() {
    return {
      categories: [
        { value: "Audi", name: "Audi" },
        { value: "BMW", name: "BMW" },
        { value: "Tesla", name: "Tesla" },
        { value: "Honda", name: "Honda" },
      ],
      showMobileMenu: false,
    };
  },
};
</script>
<style scoped="scoped">
@media (max-width: 767.98px) {
  .menu-products,
  .search-form-product {
    display: none;
  }
  .row-header {
    display: flex;
  }
  .navbar-header {
    display: unset !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid rgba(221, 221, 221, 0.49);
  }
}
</style>
